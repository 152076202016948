import React, { useState, useEffect } from 'react';
import AgreePage from './page/Agree';
import SurveyPage from './page/Survey';
import QuestionPage from './page/Question';

function App() {

  const [isAgreePage, setAgreePage] = useState(true);
  const [isSurveyPage, setSurveyPage] = useState(false);
  const [isQuestionPage, setQuestionPage] = useState(false);

  const [selectedGender, setSelectedGender] = useState(false);
  const [selectedAge, setSelectedAge] = useState(false);
  const [selectedIncome, setSelectedIncome] = useState(false);

  const setPageStates = {
    setAgreePage,
    setSurveyPage,
    setQuestionPage,
  };

  const setSurveyStates = {
    setSelectedGender,
    setSelectedAge,
    setSelectedIncome,
  };

  const surveyData = {
    selectedGender,
    selectedAge,
    selectedIncome,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isAgreePage, isSurveyPage, isQuestionPage]);

  return (
    <div style={{ height: '100vh' }}>
      {isAgreePage && <AgreePage setPageStates={setPageStates} />}
      {isSurveyPage && <SurveyPage setPageStates={setPageStates} setSurveyStates={setSurveyStates} setSurveyData={surveyData}/>}
      {isQuestionPage && <QuestionPage surveyData={surveyData} />}
    </div>
  );
}

export default App;
