import React from 'react';
import '../App.css';

function App({ setPageStates }) {

  const handleCheckboxChange = () => {
    // Survey page open
    setPageStates.setAgreePage(false);
    setPageStates.setSurveyPage(true);
    setPageStates.setQuestionPage(false);
  };

  
  return (
    <div className="nsv_form nsv_theme_light nsv_font_1 nsv_skin_color_purple nsv_skin_background_color_lightgray " style={{height: 'auto'}}>
        {/*<div className="nsv_header_box">
            <header className="nsv_header ">
                <div id="nsv_header_toolbar" style={{display: "contents"}}></div>
                <div></div>
            </header>
        </div>*/}
        <div className="nsv_body nsv_page_reply">
            <div className="nsv_survey_reply">
              <div className="nsv_survey_item nsv_survey_header">
                  <div className="nsv_survey_header_image_area   skinColor12"><svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className="nsv_survey_header_image_icon">
                          <g>
                              <path fillRule="evenodd" clipRule="evenodd" d="M30.4833 20.7498H30.0023C23.9264 20.7498 19 25.6755 19 31.7498V74.7498C19 80.825 23.9249 85.7498 30 85.7498H70C76.0751 85.7498 81 80.825 81 74.7498V31.7498C81 25.6752 76.0741 20.7498 69.9984 20.7498H69.5167C68.2608 24.5265 64.6984 27.25 60.5 27.25H39.5C35.3016 27.25 31.7392 24.5265 30.4833 20.7498ZM69.5165 12.7498H69.9984C80.4913 12.7498 89 21.2559 89 31.7498V74.7498C89 85.2432 80.4934 93.7498 70 93.7498H30C19.5066 93.7498 11 85.2432 11 74.7498V31.7498C11 21.2556 19.5097 12.7498 30.0023 12.7498H30.4835C31.7395 8.97334 35.3017 6.25 39.5 6.25H60.5C64.6983 6.25 68.2606 8.97334 69.5165 12.7498ZM37 15.75C37 14.3693 38.1193 13.25 39.5 13.25H60.5C61.8807 13.25 63 14.3693 63 15.75V17.75C63 19.1307 61.8807 20.25 60.5 20.25H39.5C38.1193 20.25 37 19.1307 37 17.75V15.75ZM56.0541 40.8041C54.3972 40.8041 53.0541 42.1472 53.0541 43.8041C53.0541 45.4609 54.3972 46.8041 56.0541 46.8041H68C69.6569 46.8041 71 45.4609 71 43.8041C71 42.1472 69.6569 40.8041 68 40.8041H56.0541ZM48.9189 36.5635C50.1265 37.6979 50.1858 39.5964 49.0514 40.804L41.1582 49.2064C39.754 50.7013 37.3826 50.7104 35.9669 49.2264L30.8293 43.8411C29.6857 42.6422 29.7304 40.7433 30.9292 39.5996C32.128 38.4559 34.027 38.5007 35.1707 39.6995L38.5395 43.2308L44.6783 36.696C45.8127 35.4884 47.7113 35.429 48.9189 36.5635ZM56.0541 59.1824C54.3972 59.1824 53.0541 60.5256 53.0541 62.1824C53.0541 63.8393 54.3972 65.1824 56.0541 65.1824H68C69.6569 65.1824 71 63.8393 71 62.1824C71 60.5256 69.6569 59.1824 68 59.1824H56.0541ZM48.9189 54.9418C50.1265 56.0762 50.1858 57.9748 49.0514 59.1824L41.1582 67.5848C39.754 69.0797 37.3826 69.0888 35.9669 67.6048L30.8293 62.2194C29.6857 61.0206 29.7304 59.1216 30.9292 57.978C32.128 56.8343 34.027 56.879 35.1707 58.0779L38.5395 61.6092L44.6783 55.0744C45.8127 53.8668 47.7113 53.8074 48.9189 54.9418Z" fill="url(#IconSurvey100x100White)"></path>
                          </g>
                          <linearGradient id="IconSurvey100x100White" x1="50.956" y1="4.75" x2="51.5305" y2="94.2699" gradientUnits="userSpaceOnUse">
                              <stop stopColor="#fff" stopOpacity="0.98"></stop>
                              <stop offset="100%" stopColor="#fff" stopOpacity="0.4"></stop>
                          </linearGradient>
                      </svg></div>
                  <div className="nsv_survey_item_inner" style={{ textAlign: 'center' }}>
                      <h3 className="nsv_survey_reply_question_title">거리뷰 설문조사</h3>
                      {/* <div className="nsv_survey_period">
                          <div className="nsv_survey_period_date nsv_survey_period_custom nsv_survey_period_date_complete"><span className="nsv_button_survey_period_text" style={{whiteSpace: "nowrap;"}}>2024.01.11. 오후 04:14 ~</span><span> </span><span className="nsv_button_survey_period_text">2024.02.29. 오후 11:30</span></div>
                      </div> */}
                  </div>
              </div>
              <div className="nsv_survey_item nsv_survey_question ">
                  <div className="nsv_survey_item_inner">
                    <strong className="nsv_survey_reply_question_title" style={{ textAlign: 'center' }}>
                      <span className="nsv_survey_reply_question_title_highlight">연구 설명문 및 참여 동의서</span>
                    </strong>
                      {/*<div className="nsv_survey_reply_question_description">
                          <div className="nsv_rte_textarea nsv_rte_link">
                              <p>동의를 거부하실 수 있으나 설문 참여가 불가능합니다.</p>
                          </div>
                      </div>*/}
                      <dl className="nsv_survey_question_personal_info_list">
                          {/* <div className="nsv_survey_question_personal_info_item">
                              <dt className="nsv_survey_question_personal_info_title">보유 및 이용기간</dt>
                              <dd className="nsv_survey_question_personal_info_detail nsv_survey_question_personal_info_alert">설문조사 완료 후 1개월</dd>
                          </div>*/}
                          <div className="nsv_survey_question_personal_info_item" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            <dd className="nsv_survey_question_personal_info_detail " style={{textAlign: "left"}}>- 본 설문에서 개인 정보는 수집되지 않으며, 응답 내용은 연구 목적 외에는 사용되지 않습니다.</dd>
                            <dd className="nsv_survey_question_personal_info_detail nsv_survey_question_personal_info_alert" style={{textAlign: "left"}}>- 본 설문은 10대 미성년자에게 유해한 내용을 담지 않고 있지 않으며, 미성년자도 응답 가능합니다.</dd>
                            <dd className="nsv_survey_question_personal_info_detail nsv_survey_question_personal_info_alert" style={{textAlign: "left"}}>- 본 설문은 제시하는 두 개의 상권 이미지를 보고 질문에 더 적합한 사진을 고르는 방식으로 진행되며, 사진에 대한 응답 이전에 응답자의 성별, 연령, 소득구간에 대한 정보를 묻습니다. </dd>
                            <dd className="nsv_survey_question_personal_info_detail nsv_survey_question_personal_info_alert" style={{textAlign: "left"}}>- 본 설문으로 수집된 응답자의 의견으로 인공지능을 학습시키는데 사용합니다.</dd>
                            <dd className="nsv_survey_question_personal_info_detail nsv_survey_question_personal_info_alert" style={{textAlign: "left"}}>- 설문 예상 소요시간은 1분 내외이며, 여러 이미지 세트에 대한 응답을 원하시는 경우 더 길어질 수 있습니다.</dd>
                            <dd className="nsv_survey_question_personal_info_detail nsv_survey_question_personal_info_alert" style={{textAlign: "left"}}>- 본 설문은 응답자에 대한 금전적 보상을 하지 않습니다.</dd>
                            <dd className="nsv_survey_question_personal_info_detail" style={{textAlign: "left"}}>- 본 연구는 한국연구재단의 소상공인 경제생태계 연구센터의 연구사업 일환으로 진행되며, 익명화된 연구결과물은 타 연구자 및 한국연구재단 등의 요청이 있을 시 데이터베이스화되어 공유될 수 있습니다. 따라서 익명화된 데이터베이스는 지속적으로 연구자에 의해 보관될 예정입니다.</dd>
                          </div>
                          <div className="nsv_survey_question_personal_info_item" style={{display: "flex", justifyContent: "center"}}>
                            <dd className="nsv_survey_question_personal_info_detail">귀하께서 연구참여에 동의한다면 아래 부분에 동의 체크를 하십시오.</dd>
                          </div>
                      </dl>
                      <input type="checkbox" className="nsv_survey_question_personal_info_input" checked={false} readOnly={true} />
                      <label type="button" className="nsv_survey_question_personal_info_label" onClick={handleCheckboxChange}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="nsv_survey_info_label_icon_check">
                          <path d="M3.20001 7.48261L6.69092 11.0593L12.8 4.80011" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>연구참여에 동의합니다.
                      </label>
                  </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default App;
