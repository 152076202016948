import React, { useRef } from 'react';
import '../App.css';

function Survey({ setPageStates, setSurveyStates, setSurveyData }) {

  // 포커스 설정
  const genderBtnRef = useRef(null);
  const ageBtnRef = useRef(null);
  const incomeBtnRef = useRef(null);

  const handleGenderChange = (e, gender) => {
    e.preventDefault();
    setSurveyStates.setSelectedGender(gender);
  };

  const handleAgeChange = (e, age) => {
    e.preventDefault();
    setSurveyStates.setSelectedAge(age);
  };

  const handleIncomeChange = (e, income) => {
    e.preventDefault();
    setSurveyStates.setSelectedIncome(income);
  };

  // 성별 옵션 배열
  const genderOptions = [
    { id: '0', label: '남성' },
    { id: '1', label: '여성' },
  ];
    
  // 연령대 옵션 배열
  const ageOptions = [
    { id: '0', label: '10대' },
    { id: '1', label: '20대' },
    { id: '2', label: '30대' },
    { id: '3', label: '40대' },
    { id: '4', label: '50대' },
    { id: '5', label: '60대' },
    { id: '6', label: '70대' },
    { id: '7', label: '80대 이상' },
  ];

  // 월소득 옵션 배열
  const incomeOptions = [
    { id: '0', label: '200만원 미만' },
    { id: '1', label: '200만원 이상 ~ 300만원 미만' },
    { id: '2', label: '300만원 이상 ~ 400만원 미만' },
    { id: '3', label: '400만원 이상 ~ 500만원 미만' },
    { id: '4', label: '500만원 이상' },
    { id: '5', label: '기타' },
  ];

  const onSubmitHandler = () => {    
    if (!setSurveyData.selectedGender) {
      alert("성별을 선택해 주세요.");
      return genderBtnRef.current.firstElementChild.focus();
    } else if (!setSurveyData.selectedAge) {
      alert("연령대를 선택해 주세요.");
      return ageBtnRef.current.firstElementChild.focus();
    } else if (!setSurveyData.selectedIncome) {
      alert("월 소득을 선택해 주세요.");
      return incomeBtnRef.current.firstElementChild.focus();
    }

    // Survey page open
    setPageStates.setAgreePage(false);
    setPageStates.setSurveyPage(false);
    setPageStates.setQuestionPage(true);
  }

  return (
    <div className="App">
      <header className="">
        <div>
          <div className="nsv_form nsv_theme_light nsv_font_1 nsv_skin_color_purple nsv_skin_background_color_lightgray ">
              {/*<div className="nsv_header_box">
                  <header className="nsv_header ">
                      <div id="nsv_header_toolbar" style={{display: "contents"}}></div>
                      <div></div>
                  </header>
              </div>*/}
              <div className="nsv_body nsv_page_reply">
                <div className="nsv_survey_reply">
                  
                  {/* 성별 */}
                  <div className="nsv_survey_item nsv_survey_question ">
                      <div className="nsv_survey_item_inner">
                        <strong className="nsv_survey_reply_question_title">
                          <span className="nsv_survey_reply_question_title_highlight">1. 귀하의 성별을 선택해주세요</span>
                        </strong>
                          <ul className="nsv_survey_question_list " ref={genderBtnRef}>
                            {genderOptions.map((option) => (
                              <li key={option.id} tabIndex="0" className={`nsv_survey_question_item nsv_no_tap_highlight ${setSurveyData.selectedGender === option.id ? 'nsv_survey_question_checked' : ''}`}>
                                <label className="nsv_survey_question_label_multiple_choice" onClick={(e) => handleGenderChange(e, option.id)}>
                                  <span className="nsv_survey_question_label_multiple_choice_icon"></span>
                                  <span className="nsv_survey_question_label_multiple_choice_text">{option.label}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                      </div>
                  </div>

                  {/* 연령대 */}
                  <div className="nsv_survey_item nsv_survey_question ">
                      <div className="nsv_survey_item_inner">
                        <strong className="nsv_survey_reply_question_title">
                          <span className="nsv_survey_reply_question_title_highlight">2. 귀하의 연령대를 선택해주세요</span>
                        </strong>
                          <ul className="nsv_survey_question_list " ref={ageBtnRef}>
                            {ageOptions.map((option) => (
                              <li key={option.id} tabIndex="1" className={`nsv_survey_question_item nsv_no_tap_highlight ${setSurveyData.selectedAge === option.id ? 'nsv_survey_question_checked' : ''}`}>
                                <label className="nsv_survey_question_label_multiple_choice" onClick={(e) => handleAgeChange(e, option.id)}>
                                  <span className="nsv_survey_question_label_multiple_choice_icon"></span>
                                  <span className="nsv_survey_question_label_multiple_choice_text">{option.label}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                      </div>
                  </div>

                  {/* 월소득 */}
                  <div className="nsv_survey_item nsv_survey_question ">
                      <div className="nsv_survey_item_inner">
                        <strong className="nsv_survey_reply_question_title">
                          <span className="nsv_survey_reply_question_title_highlight">3. 귀하의 월 소득을 선택해주세요</span>
                        </strong>
                          <ul className="nsv_survey_question_list " ref={incomeBtnRef}>
                            {incomeOptions.map((option) => (
                              <li key={option.id} tabIndex="2" className={`nsv_survey_question_item nsv_no_tap_highlight ${setSurveyData.selectedIncome === option.id ? 'nsv_survey_question_checked' : ''}`}>
                                <label className="nsv_survey_question_label_multiple_choice" onClick={(e) => handleIncomeChange(e, option.id)}>
                                  <span className="nsv_survey_question_label_multiple_choice_icon"></span>
                                  <span className="nsv_survey_question_label_multiple_choice_text">{option.label}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                      </div>
                  </div>
                  
                  <div className="nsv_survey_reply_button_area">
                    <button type="button" className="nsv_button nsv_button_reply_next" onClick={onSubmitHandler}>
                      <span className="nsv_button_text_overlay" style={{display: "block"}}>설문시작</span>
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Survey;
