import React, { useState, useEffect } from 'react';
import '../App.css';

function Question(surveyData) {

  const [surveyId, setSurveyId] = useState('');
  const [surveyRegTime, setSurveyRegTime] = useState('');

  const [resizedLeftImage, setResizedLeftImage] = useState(null);
  const [resizedRightImage, setResizedRightImage] = useState(null);

  const [questionId, setQuestionId] = useState(null);
  const [questionDesc, setQuestionDesc] = useState(null);

  const [picturebase, setPictureBase] = useState(false);
  const [pictureCounter, setPictureCounter] = useState(false);
  const [pictureAnswer, setPictureAnswer] = useState(false);
  const [badPicture, setBadPicture] = useState(false);

  const [requestSetting, setRequestSetting] = useState(false);
  const [selectIamge, setSelectImage] = useState(null);
  
  const badpicturePath = process.env.PUBLIC_URL + '/image/badpicture.png';

  useEffect(() => {
    fetch("/api/searchSurveyQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(res => {
        setResizedLeftImage(process.env.PUBLIC_URL + '/images' + res.lpicture.path);
        setResizedRightImage(process.env.PUBLIC_URL + '/images' + res.rpicture.path);
        setQuestionId(res.question.id);
        setQuestionDesc(res.question.question);
        setPictureBase(res.lpicture.id);
        setPictureCounter(res.rpicture.id);
      })
      .catch(error => console.log(error))
  }, [requestSetting]);

  useEffect(() => {
    if (selectIamge !== null) {
      onSubmitHandler();
    }
  }, [selectIamge]);

  const onSelectHandler = (select) => {
    setBadPicture('FALSE');
    if (select === 'left') {
      setPictureAnswer(picturebase);
    } else {
      setPictureAnswer(pictureCounter);
    }
    setSelectImage(!selectIamge)
  }

  const onBadPicturesHandler = (select) => {
    setPictureAnswer('FALSE');
    if (select === 'left') {
      setBadPicture(picturebase);
    } else {
      setBadPicture(pictureCounter);
    }
    setSelectImage(!selectIamge)
  }

  const onSubmitHandler = () => {    
    let data = {}

    // base
    data['survey_id'] = surveyId;
    data['survey_reg_time'] = surveyRegTime;
    data['survey_sex'] = surveyData.surveyData.selectedGender;
    data['survey_age'] = surveyData.surveyData.selectedAge;
    data['survey_salary'] = surveyData.surveyData.selectedIncome;

    // question
    data['picture_id_base'] = picturebase;
    data['picture_id_counter'] = pictureCounter;
    // data['question_num'] = ''; -- Back단에서 처리
    data['question_id'] = questionId;
    data['picture_id_answer'] = pictureAnswer;
    data['badpictures_picture'] = badPicture;
    
    fetch("/api/insertResult", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data })
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(res => {
        setSurveyId(res.id);
        setSurveyRegTime(res.time);
        setRequestSetting(!requestSetting);
      })
      .catch(error => console.log(error))
  }

  const onFinishHandler = () => {    
    window.location.href = "http://datakorea.io";
  }

  const onPassHandler = () => {    
    setRequestSetting(!requestSetting);
  }
  
  return (
    <div className="nsv_form nsv_theme_light nsv_font_1 nsv_skin_color_purple nsv_skin_background_color_lightgray " style={{height: 'auto'}}>
      <div className="nsv_body nsv_page_reply"  style={{height: 'auto'}}>
        <div className="nsv_survey_reply">
          <div className="nsv_survey_item nsv_survey_question ">
            <div className="nsv_survey_item_inner">
              <strong className="nsv_survey_reply_question_title" style={{ textAlign: 'center' }}>
                <span className="nsv_survey_reply_question_title_highlight">Q. {questionDesc}</span>
              </strong>
              <dl className="nsv_survey_question_personal_info_list" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '8px 8px 0px 0px'}}>
                <div style={{ flex: '1' }}>
                  <img src={resizedLeftImage} 
                      alt=" " 
                      style={{ cursor: 'pointer', width: '100%', height: 'auto', border: '2px solid transparent'}}
                      loading="lazy" 
                      onClick={() => onSelectHandler('left')}
                      onMouseOver={(e) => (e.target.style.border = '2px solid var(--skin-color)')}
                      onMouseOut={(e) => (e.target.style.border = '2px solid transparent')}
                  />
                  <div style={{ flex: '1', textAlign: 'left', display: 'flex', flexWrap: 'wrap' }} className="tooltip-container">
                    <div className="tooltip-icon" style={{ cursor: 'pointer' }}>
                      <img src={badpicturePath} 
                          alt="이미지신고" 
                          className="tooltip-icon-size"
                          onClick={() => onBadPicturesHandler('left')}
                      />
                      신고
                    </div>
                    <span className="tooltip tooltiptext">이미지가 식별되지 않거나 사진 내 도로명이 적혀 있는 경우 클릭해 주시기 바랍니다.</span>
                  </div>           
                </div>
                <div style={{ flex: '1' }}>
                  <img src={resizedRightImage} 
                      alt=" " 
                      style={{ cursor: 'pointer', width: '100%', height: 'auto', border: '2px solid transparent'}}
                      loading="lazy" 
                      onClick={() => onSelectHandler('right')}
                      onMouseOver={(e) => (e.target.style.border = '2px solid var(--skin-color)')}
                      onMouseOut={(e) => (e.target.style.border = '2px solid transparent')}
                  />  
                  <div style={{ flex: '1', textAlign: 'left', display: 'flex', flexWrap: 'wrap' }} className="tooltip-container">
                    <div className="tooltip-icon" style={{ cursor: 'pointer' }}>
                      <img src={badpicturePath} 
                            alt="이미지신고"
                            className="tooltip-icon-size"
                            onClick={() => onBadPicturesHandler('right')}
                      />
                      신고
                    </div>
                    <span className="tooltip tooltiptext">이미지가 식별되지 않거나 사진 내 도로명이 적혀 있는 경우 클릭해 주시기 바랍니다.</span>
                  </div>                             
                </div>
              </dl>
            </div>
          </div>

          <div className="nsv_survey_reply_button_area">
            <button type="button" className="bottombtn" style={{ color: '#CB68FB', backgroundColor: '#fff',border: '1px solid #CB68FB', marginRight: '15px' }} onClick={onPassHandler}>
              <span className="nsv_button_text_overlay" style={{ display: "block", color: '#CB68FB' }}>PASS</span>
            </button>
            <button type="button" className="bottombtn" style={{ color: '#fff', backgroundColor: '#CB68FB' }} onClick={onFinishHandler}>
              <span className="nsv_button_text_overlay" style={{ display: "block", color: '#fff' }}>설문종료</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
